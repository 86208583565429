<template>
  <div>
    <backToHomeButton :title="backToHomeButtonText" v-if="!panels.infoWrapper && showHome" />
    <div class="top-menu intro13" :class="{'light': (slides[currentSlide] && slides[currentSlide].type !== 8), 'dark': slides[currentSlide] && slides[currentSlide].type === 8 }">
      <ul>
        <template v-for="(item, i) in slides">
          <li :key="'key-menu-' + i" v-if="item.menuTitle && (item.showOnTopMenu || item.parent)">
            <a href="#" v-if="item.showOnTopMenu" @click.stop.prevent="handleMenu(item, i)"
            :class="{ 'active' : slides[currentSlide] && slides[currentSlide].parentId == item.parentId }">{{item.menuTitle}}</a>
            <div class="top-menu-sub-list" v-if="dropdownMenu.show && dropdownMenu.list.length > 0 && dropdownMenu.index == i">
              <div class="d-flex justify-content-end">
                <span class="menu-close-icon" @click="closeMenu()">
                  <img :src="require('@/assets/images/cancel-white.svg')" alt="">
                </span>
              </div>
              <ul>
                <li v-for="(ditem, index) in dropdownMenu.list" :key="'sub-menu-' + index">
                  <a @click="handleSubMenu(parseInt(ditem.realIndex))" v-text="ditem.menu_title" :class="{'active': slides[currentSlide] && slides[currentSlide].id == ditem.id}"></a>
                </li>
              </ul>
            </div>
          </li>
        </template>
      </ul>
    </div>
    <div class="app-inner">
      <div class="slides-wrapper intro13">
        <!-- use mySlides object for demo content of type 4 -->
        <template v-for="(item, index) in slides">
          <div class="a-slide" :key="'a-slide-' + index"
            :class="[
              {
                'animate' : (animate && currentSlide == index),
                'active': currentSlide == index,
                'preSlide': index < currentSlide,
                'slideUp': !item.fade,
              },
              'slide-type-' + item.type,
              item.contentPosition
            ]"
            :style="[ !item.innerImg ? { backgroundImage : 'url(' + baseUrl + item.img + ')' } : '']"
          >
            <type-one v-if="item && item.type === 1" :item="item" @handleChange="changeSlides" :baseUrl="baseUrl"></type-one>
            <type-two v-if="item && item.type === 2" :item="item" @handleChange="changeSlides" :baseUrl="baseUrl"></type-two>
            <type-three v-if="item && item.type === 3" :item="item" @handleChange="changeSlides" @triggerVideo="triggerVideo" :baseUrl="baseUrl"></type-three>
            <type-four v-if="item && item.type === 4" :item="item" @handleChange="changeSlides" :baseUrl="baseUrl"></type-four>
            <type-five v-if="item && item.type === 5" :item="item" @handleChange="changeSlides" @triggerVideo="triggerVideo" :catId="relSlideId" :baseUrl="baseUrl"></type-five>
            <type-six v-if="item && item.type === 6" :item="item" @handleChange="changeSlides" @slideToRel="handleRelSlide" :baseUrl="baseUrl"></type-six>
            <!-- Type image slide -->
            <div @click="changeSlides" v-if="item.type === 7" class="d-flex w-100 h-100"></div>
            <!-- Type iframe slide -->
            <div v-if="item.type === 8" class="iframe-view">
              <iframe sandbox="allow-same-origin allow-scripts" v-show="index == currentSlide && item.iframe_url"  :src="item.iframe_url" frameborder="0"></iframe>
              <a href="#" :class="{ 'visible' : index == currentSlide && item.iframe_url }" @click="changeSlides"
         class="continue-button text-primary continue-button-intro-10">weiter</a>
            </div>
            <marketingSlide @changeSlides="changeSlides" v-if="item.type === 20" :baseUrl="baseUrl" :item="item"></marketingSlide>
          </div>
        </template>
      </div>
      <transition name="fade-in">
        <slide-popup v-if="popupDialog" :item="popupDialog" @handlePopup="handlePopupDialog"></slide-popup>
      </transition>
    </div>
    <div v-if="video && current && [3, 5].includes(current.type)" class="custom-popup pl-md-4 pb-md-8 pt-md-6 fade-in-bottom video-popup">
      <div class="inner bg-black position-relative">
        <button @click.stop="video = false" class="close btn btn-link btn-sm z-i-1"><img src="@/assets/images/cancel-white.svg" alt=""></button>
        <video autoplay class="pop-up-video" controls :src="video"></video>
      </div>
    </div>
    <sideMenu
      :isOpened="panels.sideMenu"
      v-on:togglePanel="togglePanel"
      v-on:handleIntroVideo="handleIntroVideo"
      :slides="slides"
      :currentSlide="currentSlide"
      :hasIntro="false"
      :intro="intro"
      v-on:changeSlides="changeSlides"></sideMenu>
    <infoWrapper
      v-if="showInfoBox"
      :isOpened="panels.infoWrapper"
      v-on:togglePanel="togglePanel"
      :tabs="infoBoxData"></infoWrapper>
    <bottomVideos
      :isOpened="panels.bottomVideos"
      v-on:togglePanel="togglePanel"></bottomVideos>
      <videoWrapper
      v-if="current && current.video_box && current.video_box.length >= 1"
      :isOpened="panels.videoWrapper"
      :videos="current.video_box || null"
      v-on:togglePanel="togglePanel"></videoWrapper>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapState } from 'vuex'
import dynamicModule from '@/store/utils/dynamicModule'
import slidesStore from '@/store/modules/slides'
import infoWrapper from '@/components/infoWrapper'
import sideMenu from '@/components/sideMenu'
import bottomVideos from '@/components/bottomVideos'
import backToHomeButton from '@/components/backToHomeButton'
import videoWrapper from "@/components/videoWrapper"
import typeOne from '@/components/intro13/typeOne'
import typeTwo from '@/components/intro13/typeTwo'
import typeThree from '@/components/intro13/typeThree'
import typeFour from '@/components/intro13/typeFour'
import typeFive from '@/components/intro13/typeFive'
import typeSix from '@/components/intro13/typeSix'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import marketingSlide from '@/components/marketingSlide'

export default {
  name: 'app',
  components: {
    infoWrapper,
    sideMenu,
    bottomVideos,
    backToHomeButton,
    Swiper,
    SwiperSlide,
    videoWrapper,
    typeOne,
    typeTwo,
    typeThree,
    typeFour,
    typeFive,
    typeSix,
    marketingSlide
  },
  mixins: [
    dynamicModule('slides', slidesStore)
  ],
  computed: {
    ...mapState('slides', ['slides', 'baseUrl', 'intro', 'introInfoPage'])
  }, 
  watch: {
    current (nv, val) {
      this.initEvents(nv && nv.type !== 4)
    },
    currentSlide (nv, ov) {
      if (nv !== null) {
        switch (nv) {
          case 0:
            this.handleInfoBox(this.introInfoPage || null)
            break
          default:
            this.handleInfoBox(this.current?.info_page || null)
            break
        }
      }
    }
  },
  data () {
    return {
      // currentSlide: 15 // type 4 (this.itmFour) in dev,
      currentSlide: 1,
      animate: true,
      showLoader: false,
      digitalCheckout: false,
      backToHomeButtonText: 'MOBILFUNK',
      popupDialog: null,
      dropdownMenu: {
        show: false,
        index: null,
        list: []
      },
      dropdownMenuList: [],
      isProduction: process.env.NODE_ENV === 'production',
      current: null,
      showIntro: false,
      showHome: true,
      isAnimating: false,
      imageCache: [],
      isVideoPlaying: true,
      panels: {
        'sideMenu': false,
        'infoWrapper': false,
        'bottomVideos': false,
        'videoWrapper': false
      },
      xDown: null,
      yDown: null,
      slideVideo: false,
      showInfoBox: false,
      infoBoxData: null,
      isSliderActive: false,
      relSlideId: null,
      video: false,
      // mySlides: {}, // Use it for type 4 demo content
      itmFour: {
        type: 4,
        contentPosition: "center",
        fade: true,
        hasPopup: false,
        has_password: false,
        id: 188,
        img: "",
        innerImg: "",
        menuTitle: "MagentaMobil Tarife",
        menu_title: "MagentaMobil Tarife",
        nextButton: false,
        nextButtonText: "",
        parent: false,
        parentId: "180",
        showOnTopMenu: false,
        title: 'MagentaMobil Tarife inklusive 5G',
        packages: {
          xs: {
            main: {
              speed: '5 GB',
              title: 'XS',
              price: '34.95 €',
              content_list: [
                'Bestes Netz mit LTE Max',
                'Bestes 5G-Netz, wo verfügbar',
                'Telefonie- und SMS-Flat',
                'Hot-Spot-Flatrate',
                'EU-Roaming inkl. CH und UK'
              ],
              info: 'Wunsch-Smartphone ab zusätzlich 10 € mtl.'
            },
            eins: {
              speed: '10 GB',
              title: 'XS',
              price: '29.95 €',
              content_list: [
                'Bestes Netz mit LTE Max',
                'Bestes 5G-Netz, wo verfügbar',
                'Telefonie- und SMS-Flat',
                'Hot-Spot-Flatrate',
                'EU-Roaming inkl. CH und UK'
              ],
              info: 'Wunsch-Smartphone ab zusätzlich 10 € mtl.'
            },
            young: false,
            prepaid: false,
            mix: false
          },
          s: {
            main: {
              speed: '10 GB',
              title: 'S',
              price: '39.95 €',
              content_list: [
                'Bestes Netz mit LTE Max',
                'Bestes 5G-Netz, wo verfügbar',
                'Telefonie- und SMS-Flat',
                'Hot-Spot-Flatrate',
                'EU-Roaming inkl. CH und UK'
              ],
              info: 'Wunsch-Smartphone ab zusätzlich 10 € mtl.'
            },
            eins: {
              speed: '20 GB',
              title: 'S',
              price: '34.95 €',
              content_list: [
                'Bestes Netz mit LTE Max',
                'Bestes 5G-Netz, wo verfügbar',
                'Telefonie- und SMS-Flat',
                'Hot-Spot-Flatrate',
                'EU-Roaming inkl. CH und UK'
              ],
              info: 'Wunsch-Smartphone ab zusätzlich 10 € mtl.'
            },
            young: {
              speed: '10 GB',
              title: 'S',
              price: '19.95 €',
              content_list: [
                'Bestes Netz mit LTE Max',
                'Bestes 5G-Netz, wo verfügbar',
                'Telefonie- und SMS-Flat',
                'Hot-Spot-Flatrate',
                'EU-Roaming inkl. CH und UK'
              ],
              info: 'Wunsch-Smartphone ab zusätzlich 10 € mtl.'
            },
            prepaid: {
              speed: '500 MB',
              title: 'S',
              price: '4,95 € / 4Wochen',
              content_list: [
                '4G/LTE Max',
                'Flatrate ins Telekom Mobilfunknet',
              ],
              info: '10 € Startguthaben'
            },
            mix: {
              speed: '18 GB',
              title: 'S',
              price: '14.95 €',
              content_list: [
                'Bestes Netz mit LTE Max',
                'Bestes 5G-Netz, wo verfügbar',
                'Telefonie- und SMS-Flat',
                'Hot-Spot-Flatrate',
                'EU-Roaming inkl. CH und UK'
              ],
              info: 'Wunsch-Smartphone ab zusätzlich 10 € mtl.'
            }
          },
          m: {
            main: {
              speed: '20 GB',
              title: 'M',
              price: '49.95 €',
              content_list: [
                'Bestes Netz mit LTE Max',
                'Bestes 5G-Netz, wo verfügbar',
                'Telefonie- und SMS-Flat',
                'Hot-Spot-Flatrate',
                'EU-Roaming inkl. CH und UK'
              ],
              info: 'Wunsch-Smartphone ab zusätzlich 10 € mtl.'
            },
            eins: {
              speed: '40 GB',
              title: 'M',
              price: '44.95 €',
              content_list: [
                'Bestes Netz mit LTE Max',
                'Bestes 5G-Netz, wo verfügbar',
                'Telefonie- und SMS-Flat',
                'Hot-Spot-Flatrate',
                'EU-Roaming inkl. CH und UK'
              ],
              info: 'Wunsch-Smartphone ab zusätzlich 10 € mtl.'
            },
            young: {
              speed: '21 GB',
              title: 'M',
              price: '29.95 €',
              content_list: [
                'Bestes Netz mit LTE Max',
                'Bestes 5G-Netz, wo verfügbar',
                'Telefonie- und SMS-Flat',
                'Hot-Spot-Flatrate',
                'EU-Roaming inkl. CH und UK'
              ],
              info: 'Wunsch-Smartphone ab zusätzlich 10 € mtl.'
            },
            prepaid: {
              speed: '3 GB',
              title: 'M',
              price: '9,95 € / 4Wochen',
              content_list: [
                '5G/LTE Max',
                'Flatrate in alle deutschen Netze /inkl. Festnetz)'
              ],
              info: '10 € Startguthaben'
            },
            mix: {
              speed: '36 GB',
              title: 'M',
              price: '24.95 €',
              content_list: [
                'Bestes Netz mit LTE Max',
                'Bestes 5G-Netz, wo verfügbar',
                'Telefonie- und SMS-Flat',
                'Hot-Spot-Flatrate',
                'EU-Roaming inkl. CH und UK'
              ],
              info: 'Wunsch-Smartphone ab zusätzlich 10 € mtl.'
            }
          },
          l: {
            main: {
              speed: '40 GB',
              title: 'L',
              price: '59.95 €',
              content_list: [
                'Bestes Netz mit LTE Max',
                'Bestes 5G-Netz, wo verfügbar',
                'Telefonie- und SMS-Flat',
                'Hot-Spot-Flatrate',
                'EU-Roaming inkl. CH und UK'
              ],
              info: 'Wunsch-Smartphone ab zusätzlich 10 € mtl.'
            },
            eins: {
              speed: 'unlimited',
              title: 'L',
              price: '54.95 €',
              content_list: [
                'Bestes Netz mit LTE Max',
                'Bestes 5G-Netz, wo verfügbar',
                'Telefonie- und SMS-Flat',
                'Hot-Spot-Flatrate',
                'EU-Roaming inkl. CH und UK'
              ],
              info: 'Wunsch-Smartphone ab zusätzlich 10 € mtl.'
            },
            young: {
              speed: '36 GB',
              title: 'L',
              price: '39.95 €',
              content_list: [
                'Bestes Netz mit LTE Max',
                'Bestes 5G-Netz, wo verfügbar',
                'Telefonie- und SMS-Flat',
                'Hot-Spot-Flatrate',
                'EU-Roaming inkl. CH und UK'
              ],
              info: 'Wunsch-Smartphone ab zusätzlich 10 € mtl.'
            },
            prepaid: {
              speed: '5 GB',
              title: 'L',
              price: '14,95 € / 4Wochen',
              content_list: [
                '5G/LTE Max',
                'Flatrate in alle deutschen Netze /inkl. Festnetz)'
              ],
              info: '15 € Startguthaben'
            },
            mix: {
              speed: '72 GB',
              title: 'L',
              price: '34.95 €',
              content_list: [
                'Bestes Netz mit LTE Max',
                'Bestes 5G-Netz, wo verfügbar',
                'Telefonie- und SMS-Flat',
                'Hot-Spot-Flatrate',
                'EU-Roaming inkl. CH und UK'
              ],
              info: 'Wunsch-Smartphone ab zusätzlich 10 € mtl.'
            }
          },
          xl: {
            main: {
              speed: 'unlimited',
              title: 'XL',
              price: '84.95 €',
              content_list: [
                'Bestes Netz mit LTE Max',
                'Bestes 5G-Netz, wo verfügbar',
                'Telefonie- und SMS-Flat',
                'Hot-Spot-Flatrate',
                'EU-Roaming inkl. CH und UK'
              ],
              info: 'Wunsch-Smartphone ab zusätzlich 10 € mtl.'
            },
            eins: {
              speed: 'unlimited',
              title: 'XL',
              price: '79.95 €',
              content_list: [
                'Bestes Netz mit LTE Max',
                'Bestes 5G-Netz, wo verfügbar',
                'Telefonie- und SMS-Flat',
                'Hot-Spot-Flatrate',
                'EU-Roaming inkl. CH und UK'
              ],
              info: 'Wunsch-Smartphone ab zusätzlich 10 € mtl.'
            },
            young: {
              speed: 'unlimited',
              title: 'XL',
              price: '64.95 €',
              content_list: [
                'Bestes Netz mit LTE Max',
                'Bestes 5G-Netz, wo verfügbar',
                'Telefonie- und SMS-Flat',
                'Hot-Spot-Flatrate',
                'EU-Roaming inkl. CH und UK'
              ],
              info: 'Wunsch-Smartphone ab zusätzlich 10 € mtl.'
            },
            prepaid: {
              speed: '7 GB',
              title: 'XL',
              price: '24,95 € / 4Wochen',
              content_list: [
                '5G/LTE Max',
                'Flatrate in alle deutschen Netze /inkl. Festnetz)'
              ],
              info: '25 € Startguthaben'
            },
            mix: {
              speed: 'unlimited',
              title: 'XL',
              price: '59.95 €',
              content_list: [
                'Bestes Netz mit LTE Max',
                'Bestes 5G-Netz, wo verfügbar',
                'Telefonie- und SMS-Flat',
                'Hot-Spot-Flatrate',
                'EU-Roaming inkl. CH und UK'
              ],
              info: 'Wunsch-Smartphone ab zusätzlich 10 € mtl.'
            }
          },
          max: {
            main: false,
            eins: false,
            young: false,
            prepaid: {
              speed: 'unlimited',
              title: 'MAX',
              price: '99,95 € / 4Wochen',
              content_list: [
                '5G/LTE Max',
                'Flatrate in alle deutschen Netze /inkl. Festnetz)'
              ],
              info: '100 € Startguthaben'
            },
            mix: false
          }
        }
      }
    }
  },
  methods: {
    ...mapActions('slides', ['searchSlides', 'clearSlide', 'addLoading']),
    handleIntroVideo () {
      this.showIntro = true
      this.currentSlide = 0
      // this.current = null
      let video = this.$refs.$video
      if (video) {
        let playingState = !!(video.currentTime > 0 && !video.paused && !video.ended && video.readyState > 2)
        if (playingState) {
          video.pause()
          this.isVideoPlaying = false
        } else {
          video.play()
          this.isVideoPlaying = true
        }
      }
    },
    hideIntro (changeSlide = true) {
      this.currentSlide = 0
      this.changeSlides()
    },
    changeSlides (id) {
      // debugger
      if (this.isAnimating || this.isSliderActive) {
        return false
      }
      if (id instanceof MouseEvent) id = null

      if (this.current && this.current.innerSlider && !this.isSliderActive) {
        this.isSliderActive = true
        return
      }

      if (this.current && this.current.type === 5) {
        setTimeout(() => {
          this.relSlideId = null
        }, 1200)
      }

      this.isAnimating = true
      this.showLoader = true
      this.animate = true


      setTimeout(() => {
        this.showLoader = false
        this.showIntro = false
        if (id === undefined || id === null) {
          this.currentSlide++
        } else if (typeof id === 'string' && ['r', 'l', 'u', 'd'].indexOf(id) > -1) {
          if (id === 'l' || id === 'u') this.currentSlide++
          else this.currentSlide--
        } else this.currentSlide = id
        if (this.currentSlide > (Object.keys(this.slides).length) || this.currentSlide === 0) this.handleIntroVideo()
        this.current = this.currentSlide === 0 ? this.slides[0] : this.slides[this.currentSlide]

        setTimeout(() => {
          this.animate = false
          this.isAnimating = false
          if (this.$route.params?.id) {
            this.addLoading(false)
          }
        }, 500)
      }, 300)
    },
    togglePanel (panelName, value) {
      for (let item in this.panels) {
        if (item === panelName) this.panels[panelName] = !value
        else this.panels[item] = false
      }
    },
    getTouches (evt) {
      return evt.touches || evt.originalEvent.touches
    },
    handleTouchStart (evt) {
      const firstTouch = this.getTouches(evt)[0]
      this.xDown = firstTouch.clientX
      this.yDown = firstTouch.clientY
    },
    handleTouchMove (evt) {
      let $this = this
      if (!this.xDown || !this.yDown || $this.isAnimating) {
        return
      }

      var xUp = evt.touches[0].clientX
      var yUp = evt.touches[0].clientY

      var xDiff = this.xDown - xUp
      var yDiff = this.yDown - yUp

      if (Math.abs(xDiff) > Math.abs(yDiff)) {
        if (xDiff > 0) {
          $this.changeSlides('l')
        } else {
          $this.changeSlides('r')
        }
      } else {
        if (yDiff > 0) {
          $this.changeSlides('u')
        } else {
          $this.changeSlides('d')
        }
      }
      /* reset values */
      this.xDown = null
      this.yDown = null
    },
    wheelFunction (e) {
      let $this = this
      var dir = Math.sign(e.deltaY)

      if (!$this.isAnimating) {
        if (dir === -1) {
          $this.changeSlides('r')
        } else {
          $this.changeSlides('l')
        }
      }
      e.preventDefault()
      e.stopPropagation()
      return false
    },
    initEvents (bool) {
      let $this = this

      let ele = document.querySelector('.slides-wrapper')
      if (bool) {
        ele.addEventListener('touchstart', $this.handleTouchStart, false)
        ele.addEventListener('touchmove', $this.handleTouchMove, false)
        ele.addEventListener('wheel', $this.wheelFunction)
      } else {
        ele.removeEventListener('touchstart', $this.handleTouchStart, false)
        ele.removeEventListener('touchmove', $this.handleTouchMove, false)
        // ele.removeEventListener('wheel', $this.wheelFunction)
      }
    },
    removeWheelEvent (bool) {
      let $this = this

      let ele = document.querySelector('.slides-wrapper')
      if (bool) {
        // ele.addEventListener('touchstart', $this.handleTouchStart, false)
        // ele.addEventListener('touchmove', $this.handleTouchMove, false)
        ele.addEventListener('wheel', $this.wheelFunction)
      } else {
        // ele.removeEventListener('touchstart', $this.handleTouchStart, false)
        // ele.removeEventListener('touchmove', $this.handleTouchMove, false)
        ele.removeEventListener('wheel', $this.wheelFunction)
      }
    },
    openVideo (url) {
      this.slideVideo = (url === undefined) ? '://0' : this.baseUrl + url
    },
    handleInfoBox (data) {
      this.infoBoxData = data?.content_elements || null
      this.showInfoBox = data?.content_elements?.length > 0
    },
    handleInnerSlides (item, index) {
      this.changeSlides(parseInt(this.currentSlide) + index + 1)
    },
    handlePopupDialog (item) {
      this.popupDialog = item
    },
    handleMenu (item, index) {
      if (this.dropdownMenu.show && index == this.dropdownMenu.index) {
        this.changeSlides(index)
        this.dropdownMenu = {
          index: null,
          list: [],
          show: false
        }
        return
      } else {
        this.dropdownMenu = {
          index: null,
          list: [],
          show: false
        }
        if (item.parent) {
          this.dropdownMenu.index = index
          for (let key in this.slides) {
            if (this.slides[key] && this.slides[key].parentId == item.id && this.slides[key].parentId != this.slides[key].id) {
              let item = this.slides[key]
              item.realIndex = key
              this.dropdownMenu.list.push(item)
            }
          }
          this.dropdownMenu.show = true
        }
      }
    },
    closeMenu () {
      this.dropdownMenu = {
        index: null,
        list: [],
        show: false
      }
    },
    handleSubMenu (index) {
      this.changeSlides(index)
      this.closeMenu()
    },
    handleRelSlide (id) {
      this.relSlideId = id
      this.changeSlides(this.currentSlide + 1)
    },
    triggerVideo (url) {
      // this.video = '://0'
      this.video = (url === undefined) ? 'https://675-sliders-backend.c-1458.maxcluster.net/storage/app/media/videos/telekom-magentatv-mehr-fuer-alle.mp4' : this.$store.state.slides.baseUrl + url
      // this.video = (url === undefined) ? '://0' : require('@/assets/videos/' + url)
    }
  },
  mounted () {
    let self = this
    let routeID = this.$route.params?.id && parseInt(this.$route.params.id)
    this.searchSlides(13).then(() => {
      this.handleInfoBox(this.introInfoPage)
      this.$nextTick(() => {
        Object.keys(this.slides).forEach((key) => {
          let b = new Image()
          b.src = self.slides[key].img
          self.imageCache.push(b)
        })
        // Use it for type 4 demo content
        // this.mySlides = {...this.slides, 15: this.itmFour} 
        if (this.$route.params?.id) {
          let slug = Object.keys(this.slides).filter((el) => this.slides[el].slug === this.$route.params?.id)
          if (slug.length > 0) { routeID = slug[0] } else if (!isNaN(this.$route.params?.id)) { routeID = this.$route.params?.id }
        }
        if (routeID && routeID <= Object.keys(this.slides).length) {
          this.showLoader = true
          this.animate = this.isAnimating = false
          setTimeout(() => {
            this.changeSlides(routeID)
          }, 300)
        }
        self.initEvents(true)
      })
    })
    this.initEvents(true)
    this.showHome = this.$route.query.hm === undefined && this.$route.params.id === undefined
    this.showHome = true
  },
  created () {},
  beforeDestroy () {
    this.clearSlide()
  }
}
</script>
